import { call, put } from 'redux-saga/effects'
import { AreaManagement } from '@apiomat/sdk'
import DashboardSlice from './DashboardSlice'

export function* loadContacts() {
  try {
    const contacts: AreaManagement.BackofficeContact[] = yield call(() => AreaManagement.BackofficeContact.getBackofficeContacts('limit 100'))
    yield put(DashboardSlice.actions.loadContactsSuccess(contacts))
  } catch (err) {
    yield put(DashboardSlice.actions.loadContactsFailure(JSON.stringify(err)))
  }
}

export function* loadTasks() {
  try {
    const tasks: AreaManagement.Task[] = yield call(() => AreaManagement.Task.getTasks())
    yield put(DashboardSlice.actions.loadTasksSuccess(tasks))
  } catch (err) {
    yield put(DashboardSlice.actions.loadTasksFailure(JSON.stringify(err)))
  }
}
