import React from "react"
import "./InlineAddress.scss"

interface InlineAddressProps {
  address: {
    name?: string
    street?: string
    streetNumber?: string
    zipCode?: string
    city?: string
    country?: string
  }
}

export const InlineAddress: React.FC<InlineAddressProps> = ({ address }) => {
  const { street, streetNumber, zipCode, city, country } = address

  const addressText = [
    `${street || ""} ${streetNumber || ""}`,
    `${zipCode || ""} ${city || ""}`,
    `${country || ""}`
  ]
    .filter(partial => !!partial)
    .join(", ")

  return <span className="address">{addressText}</span>
}
