import { FilterOptions } from '../Models/FilterOption';

export const TaskFilterOptions: FilterOptions = [
  {
    title: 'Status',
    value: 'status',
    type: 'select',
    values: [
      { title: 'offen', value: 'offen'},
      { title: 'erledigt', value: 'erledigt'},
    ]
  },
  {
    title: 'Erfasser',
    value: 'creator',
    type: 'text',
  },
]