import React from "react"
import "./TaskDetail.scss"
import { Paper, Button, CircularProgress } from "@material-ui/core"
import { Check as CheckIcon } from "@material-ui/icons"
import { Task } from '@apiomat/sdk/areamanagement'

interface TaskDetailProps {
  task: Task
  onComplete?: (task: Task) => any
  loading?: boolean
}

export const TaskDetail: React.FC<TaskDetailProps> = ({
  task,
  onComplete = () => {},
  loading = false,
}) => {
  return (
    <Paper className="task-detail">
      <div className="task-detail__section">
        <h3 className="task-detail__title">
          {task.title}
          {task.foreignId ? ` - ${task.foreignId}` : ""}
        </h3>
        <p className="task-detail__label">Kurzbeschreibung:</p>
        <p className="task-detail__value">{task.shortDescription || "-"}</p>
        <p className="task-detail__label">Beschreibung:</p>
        <p className="task-detail__value">{task.description || "-"}</p>
        {/* {task.status === "erledigt" ? (
          <Button variant="text" color="primary" startIcon={<DoneIcon />}>
            Als offen markieren
          </Button>
        ) : null} */}
        {task.status === "offen" ? (
          <Button
            variant="text"
            color="primary"
            startIcon={<CheckIcon />}
            onClick={() => onComplete(task)}
          >
            Als erledigt markieren
          </Button>
        ) : null}
      </div>
      <div className="task-detail__section">
        {/* <h5 className="task-detail__section-title">Einzelkunden</h5>
        <div className="task-detail__customers">
          {customers.map(customer => (
            <CustomerListItem
              key={customer.ID}
              className="task-detail__customer"
              customer={customer}
            />
          ))}
        </div> */}
      </div>
      {loading ? (
        <div className="task-detail__overlay">
          <CircularProgress color="primary" />
        </div>
      ) : null}
    </Paper>
  )
}
