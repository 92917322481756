import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { Credentials, LoginResult } from './models';
import { AOMSessionToken } from '@apiomat/sdk'
import { User } from '@apiomat/sdk/basics'

export interface LoginState {
  tokenMap: AOMSessionToken | null
  user: User | null
  loading: boolean
  errorMessage: string | null
}

const INITIAL_STATE: LoginState = {
 tokenMap: null,
 user: null,
 loading: false,
 errorMessage: null
}

const login: CaseReducer<LoginState, PayloadAction<Credentials>> = (state: any, action) => {
  return { ...state, loading: true, errorMessage: null }
}

const loginWithToken: CaseReducer<LoginState, PayloadAction<AOMSessionToken>> = (state: any, action) => {
  return { ...state, loading: true, errorMessage: null }
}

const loginSuccess: CaseReducer<LoginState, PayloadAction<LoginResult>> = (state: any, { payload: { tokenMap, user } }) => {
  return { ...state, loading: false, tokenMap, user, errorMessage: null }
}

const loginFailure: CaseReducer<LoginState, PayloadAction<string>> = (state: any, { payload }) => {
  return { loading: false, user: null, tokenMap: null, errorMessage: payload }
}

const logout: CaseReducer<LoginState> = (state: any) => {
  return { ...state, loading: false, user: null, tokenMap: null }
}

const loginSlice = createSlice({
  name: 'login',
  initialState: INITIAL_STATE,
  reducers: {
    login,
    loginSuccess,
    loginFailure,
    loginWithToken,
    logout
  }
})

export const selectors = {
  selectEmail: () => {
    return (state: any): string | null => state[loginSlice.name].user.userAttributes.mail ?? null
  },
}

export const actions = loginSlice.actions

export default loginSlice.reducer
