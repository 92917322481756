import React from "react"
import { Typography } from "@material-ui/core"
import { InlineAddress } from "../../Components/InlineAddress/InlineAddress"
import "./CustomerListItem.scss"
import { BackofficeContact } from '@apiomat/sdk/areamanagement'

interface CustomerListItemProps {
  customer: BackofficeContact
  className?: string
  selected?: boolean
  onClick?: () => void
  style?: any
}

export const CustomerListItem: React.FC<CustomerListItemProps> = ({
  customer,
  selected,
  onClick,
  className = "",
  ...props
}) => {
  return (
    <div
      style={props.style}
      onClick={onClick}
      className={[
        className,
        "list-item__container",
        selected && "list-item__container--selected"
      ].join(" ")}
    >
      <div className="list-item__header">
        <div className="list-item__customer-number">
          <span>K-Nr.: </span>
          <span>{customer.customerNumber}</span>
        </div>
      </div>

      <div>
        <Typography variant="h4">{customer.name}</Typography>
      </div>
      <div className="list-item__address-container">
        <InlineAddress address={customer} />
      </div>
    </div>
  )
}
