import { createMuiTheme } from '@material-ui/core'
import { Colors } from './';

export const theme = createMuiTheme({
  palette: {
    primary: { main: Colors.primary },
    warning: { main: Colors.warn },
    success: { main: Colors.success },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        padding: '1.5em 2em',
        '&$disabled': {
          opacity: 1,
          backgroundColor: Colors.background.grey,
        }
      },
      containedPrimary: {
        color: Colors.text.white
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorPrimary: {
        backgroundColor: Colors.background.lightGrey
      }
    },
    MuiBadge: {
      colorPrimary: {
        color: Colors.text.white
      }
    },
    MuiPaper: {
      root: {
        borderRadius: 0
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0
      }
    }
  },
  typography: {
    fontFamily: 'Arial, Helvetica, sans-serif',
    button: {
      fontSize: '0.65em',
      fontWeight: 900
    },
    h4: {
      color: Colors.primary,
      fontSize: '0.85rem',
      textTransform: 'uppercase'
    },
    body1: {
      lineHeight: 1
    }
  }
})

