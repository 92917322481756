import { Container, Grid } from "@material-ui/core"
import React, { useEffect, useState, useCallback } from "react"
import { FilterBar } from "../../Components/FilterBar/FilterBar"
import { TaskDetail } from "../Components/TaskDetail/TaskDetail"
import { TaskList } from "../Components/TaskList"
import { FilterSelection } from "../DashboardSlice"
import * as TaskService from "../Services/TaskService"
import { TaskFilterOptions } from "./TaskFilterOptions"
import "./TasksScreen.scss"
import InfiniteScroll from "react-infinite-scroll-component"
import { Task } from '@apiomat/sdk/areamanagement'

export const TasksScreen = () => {
  const [tasks, setTasks] = useState([] as Task[])
  const [completed, setCompleted] = useState(false)
  const [selectedTask, setSelectedTask] = useState(null as Task | null)
  const [pagedTasks, setPagedTasks] = useState(TaskService.getPagedTasks())
  const [busyTasks, setBusyTasks] = useState({} as { [id: string]: boolean })
  const [filters, setFilters] = useState([] as FilterSelection[])
  let scrollRef: InfiniteScroll | null = null

  const completeTask = async (task: Task) => {
    if(task.ID !== undefined){
      setBusyTasks({ ...busyTasks, [task.ID!]: true })
      try {
        await TaskService.completeTask(task)
        await task.load()
        setTasks([...tasks])
      } catch (err) {
        console.error(err)
      } finally {
        delete busyTasks[task.ID]
        setBusyTasks(busyTasks)
      }
    }
  }

  const loadTasksWithFilters = useCallback(async () => {
    const pagedTasks = TaskService.getPagedTasks(filters)
    const tasks = await pagedTasks.next()

    /* This is need because the used library has no way to reset the page counter via prop or method */
    if (scrollRef) {
      scrollRef["actionTriggered"] = false
      scrollRef["lastScrollTop"] = 0
    }

    setCompleted(pagedTasks.completed)
    setPagedTasks(pagedTasks)
    setTasks(tasks)
  }, [filters, scrollRef])

  const onNextPage = async () => {
    const tasks = await pagedTasks.next()
    setTasks(tasks)
    setCompleted(pagedTasks.completed)
  }

  const addFilter = async (filter: FilterSelection) => {
    setFilters([...filters, filter])
  }

  const removeFilter = async (index: number) => {
    filters.splice(index, 1)
    setFilters([...filters])
  }

  useEffect(() => {
    loadTasksWithFilters()
  }, [loadTasksWithFilters,filters])

  const handleTaskSelection = (task: Task) => {
    if (task === selectedTask) {
      setSelectedTask(null)
    } else {
      setSelectedTask(task)
    }
  }

  return (
    <Container className="tasks">
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FilterBar
            onAdd={addFilter}
            onDelete={removeFilter}
            filterOptions={TaskFilterOptions}
            activeFilters={filters}
          />
          <div className="tasks__list">
            <TaskList
              tasks={tasks}
              busyTasks={busyTasks}
              onSelect={handleTaskSelection}
              selectedId={selectedTask?.ID}
              completed={completed}
              onNext={onNextPage}
              innerRef={ref => (scrollRef = ref)}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          {selectedTask && selectedTask.ID &&  (
            <TaskDetail
              task={selectedTask}
              loading={!!busyTasks[selectedTask.ID!]}
              onComplete={completeTask}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
