import { configureStore, combineReducers } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import loginReducer, { actions as loginActions } from '../Login/LoginSlice';
import { loginSaga, logoutSaga } from '../Login/LoginSaga';
import DashboardSlice from '../Dashboard/DashboardSlice'
import { loadContacts, loadTasks } from '../Dashboard/DashboardSaga';
import { getInitalState } from '../StartUpService';
import { startUp } from '../StartUpSaga';
import StartUpSlice from '../StartUpSlice';

const sagaMiddleware = createSagaMiddleware()

function* rootSaga() {
  yield all([
    takeLatest(loginActions.login.type, loginSaga),
    takeLatest(loginActions.logout.type, logoutSaga),
    takeLatest(DashboardSlice.actions.loadContacts.type, loadContacts),
    takeLatest(DashboardSlice.actions.loadTasks.type, loadTasks),
    takeLatest(StartUpSlice.actions.startUp.type, startUp)
  ])
}

const reducer = combineReducers({
  login: loginReducer,
  [DashboardSlice.name]: DashboardSlice.reducer,
  [StartUpSlice.name]: StartUpSlice.reducer,
})

export const store = configureStore({
  reducer,
  middleware: [sagaMiddleware],
  preloadedState: (getInitalState() as any),
  devTools: true,
})

sagaMiddleware.run(rootSaga)



