import DateFnsUtils from "@date-io/date-fns"
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core"
import { Add as AddIcon } from "@material-ui/icons"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { selectors } from "../../DashboardSlice"
import * as NewTaskService from "../../Services/NewTaskService"
import "./NewAquisitionForm.scss"
import { LoadingOverlay } from "../../../Components/LoadingOverlay/LoadingOverlay"

const createMaxDate = () => {
  const millisIn180Days = Date.now() + 180 * 24 * 60 * 60 * 1000
  const date = new Date(millisIn180Days)

  return date
}

export const NewAquisitionForm: React.FC = () => {
  const selectedCustomerIds = useSelector(selectors.selectedContacts())
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    triggerValidation,
    formState
  } = useForm()
  const [startDate, setStartDate] = useState(new Date())

  useEffect(() => {
    register({ name: "startDate", defaultValue: new Date() })
  }, [register])

  const handleDateChange = (date: any) => {
    setStartDate(date)
    setValue("startDate", date)
  }

  const resetForm = () => {
    reset()
    setStartDate(new Date())
  }

  const onSubmit = async (data: any) => {
    const isValid = await triggerValidation()
    if (!isValid) {
      return
    }

    const newAcquisition = {
      ...data,
      dueDate: data.startDate || new Date(),
      customerIds: selectedCustomerIds
    }

    try {
      await NewTaskService.createNewAcquisistion(newAcquisition)
    } catch (err) {
      console.error(err)
    }

    resetForm()
  }

  const minDate = new Date()
  const maxDate = createMaxDate()

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form
          className="aquisition-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="action-type-label">Tätigkeit</InputLabel>
            <Controller
              control={control}
              name="type"
              defaultValue="TELEPHONE"
              onChange={ev => ev[0].target.value}
              as={
                <Select labelId="action-type-label" label="Bearbeitungsart">
                  <MenuItem value="TELEPHONE">Telefonat</MenuItem>
                  <MenuItem value="MATERIAL">Infomaterial schicken</MenuItem>
                  <MenuItem value="VISIT">Besuch</MenuItem>
                </Select>
              }
            />
          </FormControl>
          <FormControl className="aquisition-form__form-control" fullWidth>
            <KeyboardDatePicker
              inputVariant="outlined"
              inputRef={register}
              name="startDate"
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              margin="normal"
              label="Startdatum"
              value={startDate}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </FormControl>
          <Button
            disabled={!selectedCustomerIds.length}
            className="aquisition-form__submit"
            variant="contained"
            type="submit"
            color="primary"
            startIcon={<AddIcon />}
          >
            Erstellen
          </Button>
        </form>
      </MuiPickersUtilsProvider>
      {formState.isSubmitting ? <LoadingOverlay /> : null}
    </div>
  )
}
