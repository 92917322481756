import React, { useState } from "react"
import {
  makeStyles,
  Grid,
  Container,
  Tab,
  Tabs,
  Paper
} from "@material-ui/core"

import { CustomerList } from "../Components/CustomerList"
import { useSelector, useDispatch } from "react-redux"
import DashboardSlice, { selectors } from "../DashboardSlice"
import { FilterBar } from "../../Components/FilterBar/FilterBar"
import { NewTaskForm } from "../Components/NewTaskForm/NewTaskForm"
import { NewAquisitionForm } from "../Components/NewAquistionForm/NewAquisitionForm"
import { CustomerFilterOptions } from "./CustomerFilterOptions"

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: any
  value: any
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
    >
      {children}
    </div>
  )
}

const useStyles = makeStyles({
  logoContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    width: "5em",
    objectFit: "contain",
  },
  tabs: {
    marginBottom: "1em",
  },
  formContainer: {
    padding: "1em 2em",
    position: "sticky",
    top: "6em",
  },
  logoutButton: {},
  container: {
    marginTop: "6em",
    height: "calc(100% - 6em)",
  },
  paper: {
    height: "100px",
    backgroundColor: "hotpink",
  },
})

export const CreateScreen: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const contacts = useSelector(selectors.selectContacts())
  const { loading } = useSelector((state: any) => state[DashboardSlice.name])
  const [currentTab, setCurrentTab] = useState(0)
  const activeFilters = useSelector(selectors.selectActiveFilters())
  const autoCompleteOptions = useSelector(selectors.selectAutoCompleteOptions())

  return (
    <Container className={classes.container}>
      <Grid container spacing={2} style={{ height: "100%" }}>
        <Grid item xs={6}>
          <FilterBar
            filterOptions={CustomerFilterOptions}
            activeFilters={activeFilters}
            autoCompleteOptions={autoCompleteOptions}
            onAdd={(filter) =>
              dispatch(DashboardSlice.actions.addFilter(filter))
            }
            onDelete={(i) => dispatch(DashboardSlice.actions.removeFilter(i))}
          />
          <CustomerList contacts={contacts} loading={loading} />
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.formContainer}>
            <Tabs
              className={classes.tabs}
              value={currentTab}
              onChange={(ev, index) => setCurrentTab(index)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Neue Aufgabe" />
              <Tab label="Neue Aquisetätigkeit" />
            </Tabs>
            <TabPanel value={currentTab} index={0}>
              <NewTaskForm />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <NewAquisitionForm />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
