import { Button, makeStyles, Paper, CircularProgress } from "@material-ui/core"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Colors } from "../../Theme"
import DashboardSlice, { selectors } from "../DashboardSlice"
import { CustomerListItem } from "./CustomerListItem"
import { List, ListRowRenderer, AutoSizer } from 'react-virtualized'
import { BackofficeContact } from '@apiomat/sdk/areamanagement'

const useStyles = makeStyles({
  container: {
    minHeight: "2em",
    height: 'calc(100% - 6em);',
  },
  element: {
    borderBottomColor: Colors.dividers.mediumGrey,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid"
  },
  selectionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  selectionCounter: {
    fontSize: "0.8em"
  },
  lastElement: {},
  noResultsMessage: {
    padding: "1em"
  }
})

interface CustomerListProps {
  contacts: BackofficeContact[]
  loading?: boolean
}

export const CustomerList: React.FC<CustomerListProps> = ({ contacts, loading }) => {
  const classes = useStyles()
  const selectedCustomers = useSelector(selectors.selectedContacts())
  const dispatch = useDispatch()

  const toggleCustomer = (customer: BackofficeContact) => {
    dispatch(
      DashboardSlice.actions.toggleContactSelection(customer.customerNumber || '')
    )
  }

  const toggleAll = () => {
    if (contacts.length === selectedCustomers.length) {
      dispatch(DashboardSlice.actions.deselectAllContacts())
    } else {
      dispatch(
        DashboardSlice.actions.selectAllContacts(
          contacts.map(contact => contact.customerNumber || '')
        )
      )
    }
  }

  const rowRenderer: ListRowRenderer = ({
      index,
      style,
    }) => {
      const contact = contacts[index]
      return (
        <CustomerListItem
          style={style}
          key={contact.ID}
          selected={selectedCustomers.includes(contact.customerNumber!!)}
          onClick={() => toggleCustomer(contact)}
          className={classes.element}
          customer={contact}
        />
    )
  }

  return (
    <>
      <div className={classes.selectionContainer}>
        <Button onClick={toggleAll} color="primary" variant="text">
          {contacts.length === selectedCustomers.length
            ? "Alle abwählen"
            : "Alle auswählen"}
        </Button>
        <span className={classes.selectionCounter}>
          Ausgewählt: {selectedCustomers.length}
        </span>
      </div>
      <Paper className={classes.container}>
        <AutoSizer>
          {({height, width}) => (
            // @ts-ignore
            <List
              style={{ outline: 'none' }}
              rowCount={contacts.length}
              rowRenderer={rowRenderer}
              height={height}
              rowHeight={50 + 32}
              width={width}
            />
          )}
        </AutoSizer>
        {contacts.length === 0 && !loading ? (
          <p className={classes.noResultsMessage}>Keine Ergebnisse</p>
        ) : null}
        {loading ? (
          <CircularProgress color="primary" />
        ): null}
      </Paper>
    </>
  )
}
