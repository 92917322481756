export class PromiseFileReader {

  private _reader = new FileReader()

  readAsArrayBuffer(file: File | Blob): Promise<ArrayBuffer> {
    return new Promise((resolve) => {
      this._reader.onload = () => {
        resolve(this._reader.result as ArrayBuffer)
      }

      this._reader.readAsArrayBuffer(file)
    })
  }

  readAsDataURL(file: File | Blob): Promise<string> {
    return new Promise((resolve) => {
      this._reader.onload = () => {
        resolve(this._reader.result as string)
      }

      this._reader.readAsDataURL(file)
    })
  }
}
