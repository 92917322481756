import React from "react"
import { TaskItem } from "./TaskItem"
import { Paper, CircularProgress } from "@material-ui/core"
import InfiniteScroll from "react-infinite-scroll-component"
import { Task } from '@apiomat/sdk/areamanagement'

interface TaskListProps {
  tasks: Task[]
  onSelect?: (task: Task) => any
  selectedId?: string
  busyTasks?: { [id: string]: boolean }
  onNext?: () => any
  completed?: boolean
  innerRef?: (ref: InfiniteScroll) => any
}

export const TaskList: React.FC<TaskListProps> = ({
  tasks = [],
  onSelect,
  selectedId,
  busyTasks = {},
  onNext = () => {},
  completed = false,
  innerRef = () => {}
}) => {
  return (
    <>
      <Paper>
        <InfiniteScroll
          dataLength={tasks.length}
          next={onNext}
          hasMore={!completed}
          style={{ overflow: "initial" }}
          loader={<CircularProgress color="primary" />}
          ref={innerRef}
        >
          {tasks.map((task: Task, i) => (
            <TaskItem
              key={i}
              task={task}
              loading={!!busyTasks[task.ID!!]}
              selected={task.ID === selectedId}
              onClick={onSelect}
            />
          ))}
        </InfiniteScroll>
      </Paper>
    </>
  )
}
