const Colors = {
  transparent: 'rgba(0,0,0,0)',
  background: {
    darkGrey: '#D9D9D9',
    grey: '#F8F8F8',
    lightGrey: '#F8F8F8',
    white: '#FFFFFF',
  },
  text: {
    main: '#241F20',
    secondary: '#949599',
    darkSecondary: '#6D6D6E',
    white: '#FFFFFF',
    black: '#000000'
  },
  dividers: {
    lightGrey: '#d9d9d9',
    mediumGrey: '#DBDBDB',
  },
  primary: '#00A5E2',
  success: '#D0E202',
  error: '#C3024D',
  warn: '#EABF00',
  datePickerBackgroundColor: '#00000066',
}

export default Colors
