import { FilterOptions } from "../Models/FilterOption"

export const CustomerFilterOptions: FilterOptions = [
  {
    value: "customerNumber",
    title: "Kundennummer",
    type: "autocomplete"
  },
  {
    value: "city",
    title: "Ort",
    type: "autocomplete"
  },
  {
    value: "match",
    title: "Abnehmergruppe",
    type: "autocomplete"
  },
  {
    value: "brands",
    title: "Marke",
    type: "autocomplete"
  },
  {
    value: "depots",
    title: "Depot",
    type: "autocomplete"
  },
  {
    value: "modules",
    title: "Modul",
    type: "autocomplete"
  },
  // {
  //   value: "not_implemented",
  //   title: "Warenträgertyp",
  //   type: "autocomplete"
  // },
  {
    value: "areamanager",
    title: "Flächenbetreuer",
    type: "autocomplete"
  },
  {
    value: "country",
    title: "Land",
    type: "autocomplete"
  }
]
