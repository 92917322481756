import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'startup',
  initialState: {
    isStartingUp: false,
    done: false
  },
  reducers: {
    startUp(state) {
      if (!state.done) {
        state.isStartingUp = true
      }
    },
    startUpSuccess(state) {
      state.isStartingUp = false
      state.done = true
    }
  }
})