import DateFnsUtils from "@date-io/date-fns"
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
} from "@material-ui/core"
import { Add as AddIcon, Delete as DeleteIcon } from "@material-ui/icons"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import React, { useCallback, useEffect, useState } from "react"
import { Controller, useForm, useFieldArray } from "react-hook-form"
import { useSelector } from "react-redux"
import { selectors } from "../../DashboardSlice"
import { selectors as loginSelectors } from "../../../Login/LoginSlice"
import * as NewTaskService from "../../Services/NewTaskService"
import "./NewTaskForm.scss"
import { Autocomplete } from "@material-ui/lab"

enum TaskType {
  SIMPLE = "SIMPLE",
  DETAIL = "DETAIL",
  COMMENT = "COMMENT",
  PHOTO = "PHOTO",
  BINARY = "BINARY",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
}

export const NewTaskForm: React.FC = () => {
  const selectedCustomerIds = useSelector(selectors.selectedContacts())
  const currentUserEmail = useSelector(loginSelectors.selectEmail())
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState,
    triggerValidation,
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      selectOptions: [{ value: "" }],
      type: TaskType.SIMPLE,
    },
  })
  const { fields, append, remove } = useFieldArray({
    name: "selectOptions",
    control,
  })
  const [startDate, setStartDate] = useState(new Date())
  const [requiredAction, setRequiredAction] = useState<boolean>(false)
  const [emailsValue, setEmailsValue] = useState<string[]>(currentUserEmail ? [currentUserEmail] : [])

  useEffect(() => {
    register({ name: "startDate", defaultValue: new Date()})
    register({ name: "requiredAction", defaultValue: false })
    register({ name: "emails", defaultValue: [currentUserEmail] })
  }, [register, currentUserEmail])

  const handleEmailsValueChange = useCallback((emails: string[]) => {
    setEmailsValue(emails)
    setValue("emails", emails)
  }, [setValue])

  useEffect(() => {
    handleEmailsValueChange(currentUserEmail ? [currentUserEmail] : [])
  }, [currentUserEmail, setValue, handleEmailsValueChange])

  const taskType = watch("type") as TaskType

  const resetForm = () => {
    reset()
    setStartDate(new Date())
    setRequiredAction(false)
    setEmailsValue([])
  }

  const handleDateChange = (date: any) => {
    setStartDate(date)
    setValue("startDate", date)
  }

  const onSubmit = async (data: any) => {
    const isValid = await triggerValidation()
    if (!isValid) {
      return
    }

    if (data.type === TaskType.SIMPLE && !data.description) {
      data.type = TaskType.DETAIL
    }

    const newTaskData = {
      ...data,
      startDate: data.startDate || new Date(),
      customerIds: selectedCustomerIds,
      selectOptions: data.selectOptions?.map((entry: any) => entry.value),
    }

    try {
      await NewTaskService.createNewTask(newTaskData)
    } catch (err) {
      console.error(err)
    } finally {
    }
    resetForm()
  }

  if (taskType === TaskType.SIMPLE && requiredAction === true) {
    setValue("requiredAction", false)
    setRequiredAction(false)
  }

  return (
    <div className="new-task">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form
          className="new-task__form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl fullWidth className="new-task__form-control">
            <TextField
              variant="outlined"
              required={true}
              inputRef={register({ required: true })}
              name="title"
              label="Überschrift"
            />
          </FormControl>
          <FormControl className="new-task__form-control" fullWidth>
            <TextField
              variant="outlined"
              inputRef={register({ required: true })}
              required={true}
              name="shortDesc"
              multiline
              label="Kurzbeschreibung"
            />
          </FormControl>
          <FormControl className="new-task__form-control" fullWidth>
            <TextField
              variant="outlined"
              inputRef={register}
              name="description"
              multiline
              label="Beschreibung"
            />
          </FormControl>
          <div className="new-task__action-container">
            <FormControl className="new-task__form-control" variant="outlined">
              <InputLabel
                style={{ backgroundColor: "white" }}
                id="bearbeitungs-art-label"
              >
                Bearbeitungsart
              </InputLabel>
              <Controller
                control={control}
                name="type"
                defaultValue={TaskType.SIMPLE}
                onChange={(ev) => ev[0].target.value}
                as={
                  <Select
                    labelId="bearbeitungs-art-label"
                    label="Bearbeitungsart"
                  >
                    <MenuItem value={TaskType.SIMPLE}>Ohne</MenuItem>
                    <MenuItem value={TaskType.COMMENT}>Kommentar</MenuItem>
                    <MenuItem value={TaskType.PHOTO}>Bild</MenuItem>
                    <MenuItem value={TaskType.BINARY}>Ja/Nein</MenuItem>
                    <MenuItem value={TaskType.SINGLE_CHOICE}>
                      Einfache Auswahl
                    </MenuItem>
                    <MenuItem value={TaskType.MULTIPLE_CHOICE}>
                      Mehrfache Auswahl
                    </MenuItem>
                  </Select>
                }
              />
            </FormControl>
          </div>

          {[TaskType.SINGLE_CHOICE, TaskType.MULTIPLE_CHOICE].includes(
            taskType
          ) ? (
            <FormControl variant="outlined">
              <InputLabel
                style={{ backgroundColor: "white" }}
                id="bearbeitungs-art-label"
              >
                Auswahlmöglichkeiten
              </InputLabel>
              <ul className="new-task__select-options">
                {fields.map((field, i) => (
                  <li key={field.id} className="new-task__select-option-field">
                    <TextField
                      fullWidth
                      variant="outlined"
                      required={true}
                      inputRef={register({ required: true })}
                      name={`selectOptions[${i}].value`}
                      label={`Auswahlmöglichkeit ${i + 1}`}
                      defaultValue=""
                    />
                    <IconButton
                      onClick={() => remove(i)}
                      disabled={fields.length < 2}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </li>
                ))}
              </ul>
              <Button
                onClick={() => append({ value: "" })}
                type="button"
                color="primary"
                disabled={fields.length >= 10}
                startIcon={<AddIcon />}
              >
                Auswahlmöglichkeit hinzufügen
              </Button>
            </FormControl>
          ) : null}

          <FormControl className="new-task__form-control" fullWidth>
            <KeyboardDatePicker
              inputVariant="outlined"
              inputRef={register}
              name="startDate"
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              label="Startdatum"
              value={startDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </FormControl>
          <FormControl className="new-task__form-control" fullWidth>
            <Autocomplete
              value={emailsValue}
              multiple
              freeSolo
              options={[]}
              onChange={(e, v) => handleEmailsValueChange(v as string[])}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Per E-Mail benachrichtigen"
                  placeholder="Empfänger"
                />
              )}
              defaultValue={currentUserEmail ? [currentUserEmail] : []}
            />
          </FormControl>
          <Button
            disabled={!selectedCustomerIds.length}
            className="new-task__submit"
            variant="contained"
            type="submit"
            color="primary"
            startIcon={<AddIcon />}
          >
            Erstellen
          </Button>
        </form>
      </MuiPickersUtilsProvider>
      {formState.isSubmitting ? (
        <div className="new-task__overlay">
          <CircularProgress className="new-task__spinner" color="primary" />
        </div>
      ) : null}
    </div>
  )
}
