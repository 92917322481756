import { createSlice, PayloadAction, CaseReducer, AnyAction } from '@reduxjs/toolkit';
import { BackofficeContact, Task } from '@apiomat/sdk/areamanagement'
import * as AutoCompleteService from './Services/AutoCompleteService'
import { AutoCompleteOptions } from './Services/AutoCompleteService';

export interface FilterSelection {
  displayTitle: string
  key: string
  value: string
}

interface DashboardState {
  contacts: { [id: string]: BackofficeContact }
  autoCompleteOptions: AutoCompleteOptions
  tasks: { [id: string]: Task }
  loading: boolean
  errorMessage: string | null
  selectedContacts: string[],
  activeFilters: FilterSelection[]
}

const INITIAL_STATE: DashboardState = {
  contacts: {},
  autoCompleteOptions: {},
  selectedContacts: [] as string[],
  tasks: {},
  loading: false,
  errorMessage: null,
  activeFilters: []
}

const listToMap = <T>(arr: any[], idField = 'ID'): { [id: string]: T } => {
  return arr.reduce((acc, curr) => {
    acc[curr[idField]] = curr

    return acc
  }, {} as any)
}

const loadContacts: CaseReducer<DashboardState, AnyAction> = (state: any) => {
  return { ...state, loading: true }
}

const loadContactsSuccess: CaseReducer<DashboardState, PayloadAction<BackofficeContact[]>> = (state: any, action) => {
  const contacts = listToMap(action.payload, 'customerNumber')
  const autoCompleteOptions = AutoCompleteService.createAutoCompleteOptions(action.payload)

  return { ...state!, loading: false, contacts, autoCompleteOptions }
}

const loadContactsFailure: CaseReducer<DashboardState, PayloadAction<string>> = (state: any, action) => {
  return { ...state, errorMessage: action.payload, loading: false }
}

const loadTasks: CaseReducer<DashboardState, AnyAction> = (state: any) => {
  return { ...state, loading: true }
}

const loadTasksSuccess: CaseReducer<DashboardState, PayloadAction<Task[]>> = (state: any, action) => {
  const tasks = listToMap(action.payload)

  return { ...state, tasks, loading: false }
}

const loadTasksFailure: CaseReducer<DashboardState, PayloadAction<string>> = (state: any, action) => {
  return { ...state, errorMessage: action.payload, loading: false }
}

const toggleContactSelection: CaseReducer<DashboardState, PayloadAction<string>> = (state: any, { payload }) => {
  const set = new Set(state.selectedContacts)
  if (set.has(payload)) {
    set.delete(payload)
  } else {
    set.add(payload)
  }

  return { ...state, selectedContacts: Array.from(set) }
}

const selectAllContacts: CaseReducer<DashboardState, PayloadAction<string[]>> = (state: any, { payload }) => {
  return { ...state, selectedContacts: payload }
}

const deselectAllContacts: CaseReducer<DashboardState, AnyAction> = (state: any) => {
  return { ...state, selectedContacts: [] }
}

const addFilter: CaseReducer<DashboardState, PayloadAction<FilterSelection>> = (state: any, { payload }) => {
  return { ...state, activeFilters: [...state.activeFilters, payload]}
}

const removeFilter: CaseReducer<DashboardState, PayloadAction<number>> = (state: any, { payload }) => {
  const newFilters = [...state.activeFilters]
  newFilters.splice(payload, 1)

  return { ...state, activeFilters: newFilters }
}

const slice = createSlice({
  name: 'dashboard',
  initialState: INITIAL_STATE,
  reducers: {
    loadContacts,
    loadContactsSuccess,
    loadContactsFailure,
    loadTasks,
    loadTasksSuccess,
    loadTasksFailure,
    toggleContactSelection,
    selectAllContacts,
    deselectAllContacts,
    addFilter,
    removeFilter
  },
})

export const selectors = {
  selectContacts: (query?: string) => {
    return (state: any): BackofficeContact[] => {
      const filters: FilterSelection[] = state[slice.name].activeFilters
      const allContacts: BackofficeContact[] = Object.values(state[slice.name].contacts)

      return allContacts.filter((contact: any) => {
        return filters.every(filter => {
          let matched = false

          if (Array.isArray(contact[filter.key])) {
            matched = contact[filter.key].includes(filter.value)
          } else {
            matched = contact[filter.key] === filter.value
          }

          return matched
        })
      })
    }
  },
  selectTasks: (query?: string) => {
    return (state: any): Task[] => Object.values(state[slice.name].tasks)
  },
  selectedContacts: () => {
    return (state: any): string[] => state[slice.name].selectedContacts
  },
  selectActiveFilters: () => {
    return (state: any): FilterSelection[] => state[slice.name].activeFilters
  },
  selectAutoCompleteOptions: () => {
    return (state: any): AutoCompleteOptions => state[slice.name].autoCompleteOptions
  },
  selectContactsByIds: (customerIds: string[]) => {
    return (state: any): BackofficeContact[] => {
      if (!customerIds) {
        return []
      }

      return customerIds.map(id => state[slice.name].contacts[id]).filter(val => !!val)
    }
  }
}

export default slice
