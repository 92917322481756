import { CustomerFilterOptions } from '../Create/CustomerFilterOptions';
import { BackofficeContact } from '@apiomat/sdk/areamanagement'

export interface AutoCompleteOptions {
  [key: string]: Set<string>
}

export const createAutoCompleteOptions = (contacts: BackofficeContact[]): AutoCompleteOptions => {
  const autoCompleteOptions: AutoCompleteOptions = {}

  for (const contact of contacts) {
    for (const option of CustomerFilterOptions) {
      let optionSet = autoCompleteOptions[option.value] || new Set<string>()

      const value = contact[option.value];
      if (value) {
        if (Array.isArray(value)) {
          value.forEach(val => optionSet.add(val))
        } else {
          optionSet.add(value)
        }
      }

      autoCompleteOptions[option.value] = optionSet
    }
  }

  return autoCompleteOptions
}
