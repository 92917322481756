import { PromiseFileReader } from "../../Utils/PromiseFileReader"
import { AreaManagement } from '@apiomat/sdk'

export interface TaskData {
  title: string
  type: string
  shortDesc: string
  description: string
  startDate: Date
  customerIds: string[]
  attachment: File
  selectOptions: string[]
  emails: string[]
}

export interface AcquisitionData {
  dueDate: Date
  type: string
  customerIds: string[]
}

export const createNewTask = async (data: TaskData) => {
  const task = new AreaManagement.Task()

  task.taskType = data.type
  task.title = data.title
  task.shortDescription = data.shortDesc
  task.description = data.description
  task.customerIds = data.customerIds
  task.startDate = data.startDate
  task.selectOptions = data.selectOptions
  task.emailsList = data.emails

  await task.save()

  if (data.attachment) {
    const reader = new PromiseFileReader()
    const buffer = await reader.readAsArrayBuffer(data.attachment)
    await task.postAttachment(buffer)
  }
}

export const createNewAcquisistion = async (data: AcquisitionData) => {
  const request = new AreaManagement.BulkCreateAcquisition()

  request.acquisitionType = data.type
  request.dueDate = data.dueDate
  request.customerIds = data.customerIds

  await request.save(false)
}
