import DashboardSlice from '../Dashboard/DashboardSlice'
import { PayloadAction } from '@reduxjs/toolkit'
import { call, put } from 'redux-saga/effects'
import { Credentials, LoginResult } from './models'
import { actions } from './LoginSlice'
import { AOMCacheStrategy, AOMSessionToken, Datastore } from '@apiomat/sdk'
import { AreaManagement } from '@apiomat/sdk'

const TOKENMAP_KEY = 'stb_ad_st'

const clearTokenMap = () => {
  localStorage.removeItem(TOKENMAP_KEY)
}

const storeTokenMap = (tokenMap: AOMSessionToken) => {
  localStorage.setItem(TOKENMAP_KEY, JSON.stringify(tokenMap))
}

export const getTokenMap = () => {
  let tokenMap = null
  const storedTokenMapString = localStorage.getItem(TOKENMAP_KEY)

  if (!!storedTokenMapString) {
    try {
      tokenMap = JSON.parse(localStorage.getItem(TOKENMAP_KEY)!)
    } catch {
      console.warn('unable to read stored tokenMap')
    }
  }

  return tokenMap
}

const loginWithCredentials = async ({ userName, password }: Credentials): Promise<LoginResult> => {
  const user = new AreaManagement.AMUser(userName, password)
  Datastore.configureAsUser(user)

  setDefaults(Datastore.Instance)

  await user.loadMe()
  const tokenMap = await user.requestSessionToken(true)
  storeTokenMap(tokenMap)
  setDefaults(Datastore.Instance)

  return { user, tokenMap }
}

const loginWithToken = async (tokenMap: AOMSessionToken): Promise<LoginResult> => {
  const user = new AreaManagement.AMUser()
  let newTokenMap = tokenMap

  if (tokenMap.expirationDate > Date.now()) {
    user.sessionToken = tokenMap.sessionToken

    Datastore.configureWithSessionToken(tokenMap.sessionToken)

    try {
      console.log('login user with stored session token', tokenMap.sessionToken)
      await user.loadMe()
    } catch {
      console.warn('failed to login with existing session token, trying refresh token')
      newTokenMap = await user.requestSessionToken(true, tokenMap.refreshToken)
    }
  } else {
    console.warn('existing session token expired, trying refresh token')
    newTokenMap = await user.requestSessionToken(true, tokenMap.refreshToken)
  }
  setDefaults(Datastore.Instance)
  storeTokenMap(newTokenMap)

  return { user, tokenMap: newTokenMap }
}

const setDefaults = (dataStore: Datastore) => {
  preventCache(dataStore)
}

const preventCache = (dataStore: Datastore) => {
  dataStore.setCachingStrategy(AOMCacheStrategy.NETWORK_ONLY)
  dataStore.customHeaders = {
    ...dataStore.customHeaders,
    Expires: '-1',
    'Cache-Control': 'must-revalidate, private',
    'If-Modified-Since': '01.01.1970 00:00:00:00 CEST',
  }
}

export const UserService = {
  loginWithCredentials,
  loginWithToken,
}

export function* loginSaga(action: PayloadAction<Credentials>) {
  try {
    const result: LoginResult = yield call(() => loginWithCredentials(action.payload))
    yield put(actions.loginSuccess(result))
    yield put(DashboardSlice.actions.loadContacts())
  } catch (err) {
    yield put(actions.loginFailure(JSON.stringify(err)))
  }
}

export function* logoutSaga() {
  yield clearTokenMap()
}
