import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './LoginScreen.scss'
import { useForm, Controller, FieldValues } from 'react-hook-form'
import { OutlinedInput } from '../Components/OutlinedInput/OutlinedInput'
import { Button } from '@material-ui/core'
import { actions, LoginState } from './LoginSlice'
import { Redirect } from 'react-router-dom'

export const LoginScreen: React.FC = () => {
  const { handleSubmit, control } = useForm()
  const loginState: LoginState = useSelector((state: any) => state.login)
  const dispatch = useDispatch()

  const onSubmit = (data: FieldValues) => {
    const { userName, password } = data

    if ([userName, password].every((val) => !!val)) {
      dispatch(actions.login({ userName, password }))
    }
  }

  return (
    <>
      <div className="login">
        <div className="login__content">
          <img className="login__logo" src="assets/logo_white.png" alt="logo" />
          <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="login__input-container">
              {/* Typings for react-hook-form are broken in versions below 7 */}
              <Controller
                name="userName"
                control={control}
                as={<OutlinedInput placeholder="Email" label="Email" disabled={loginState.loading} />}
              />
              <Controller
                name="password"
                control={control}
                as={<OutlinedInput placeholder="Passwort" label="Passwort" type="password" disabled={loginState.loading} />}
              />
            </div>
            <Button type="submit" className="login__button" variant="contained" color="primary" disabled={loginState.loading}>
              Login
            </Button>
          </form>
        </div>
      </div>
      {!!loginState.user ? <Redirect to="dashboard" /> : null}
    </>
  )
}
