import { Typography } from "@material-ui/core"
import { Check } from "@material-ui/icons"
import React from "react"
import { LoadingOverlay } from "../../Components/LoadingOverlay/LoadingOverlay"
import "./TaskItem.scss"
import { Task } from '@apiomat/sdk/areamanagement'

interface TaskItemProps {
  task: Task
  onClick?: (task: Task) => any
  selected?: boolean
  loading?: boolean
}

export const TaskItem: React.FC<TaskItemProps> = ({
  task,
  onClick = () => {},
  selected = false,
  loading = false,
}) => {
  const isComplete = task.customerIds?.length === task.completionCount

  return (
    <div
      className={[
        "task-item__container",
        selected && "task-item__container--selected",
      ].join(" ")}
      onClick={() => onClick(task)}
    >
      <div className="task-item__left-pane">
        {isComplete ? (
          <div className="task-item__completion-badge task-item__completion-badge--completed">
            <Check />
          </div>
        ) : (
          <div className="task-item__completion-badge"></div>
        )}
      </div>
      <div className="task-item__main">
        <Typography variant="h4">
          {task.title}
          {task.foreignId ? ` - ${task.foreignId}` : ""}
        </Typography>
        <p className="task-item__shortDesc">{task.shortDescription}</p>
        <p className="task-item__desc">{task.description}</p>
      </div>
      <div className="task-item__aside">
        <p className="task-item__label">Ersteller:</p>
        <p className="task-item__value">{task.creator || "unbekannt"}</p>
        <p className="task-item__label">Erledigt</p>
        <p className="task-item__value">
          {task.completionCount || 0} / {task.customerIds?.length || 0}
        </p>
      </div>
      {loading ? <LoadingOverlay /> : null}
    </div>
  )
}
