import React, { useState, useRef } from "react"
import { useDispatch } from "react-redux"
import { Menu, MenuItem, IconButton } from "@material-ui/core"
import {
  Menu as MenuIcon,
  Add as AddIcon,
  PowerSettingsNew as PowerIcon,
  FormatListBulleted
} from "@material-ui/icons"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { actions } from "../../Login/LoginSlice"

const BMenu: React.FC<RouteComponentProps> = ({ history }) => {
  const [open, setOpen] = useState(false)
  const buttonRef = useRef(null)
  const dispatch = useDispatch()

  const navigate = (path: string) => {
    history.push(path)
    setOpen(false)
  }

  return (
    <>
      <IconButton
        onClick={() => setOpen(!open)}
        edge="start"
        color="primary"
        aria-label="menu"
        ref={buttonRef}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        <MenuItem onClick={() => navigate("/dashboard/create")}>
          <AddIcon /> Neue Aufgabe
        </MenuItem>
        <MenuItem onClick={() => navigate("/dashboard/tasks")}>
          <FormatListBulleted /> Alle Aufgaben
        </MenuItem>
        <MenuItem onClick={() => dispatch(actions.logout())}>
          <PowerIcon />
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}

export const BurgerMenu = withRouter(BMenu)
