import DashboardSlice from './Dashboard/DashboardSlice';
import { select, call, put } from 'redux-saga/effects'
import { UserService } from "./Login/LoginSaga"
import { LoginResult } from "./Login/models"
import { actions as loginActions } from './Login/LoginSlice'
import StartUpSlice from "./StartUpSlice"
import { AOMSessionToken } from '@apiomat/sdk'

export function* startUp() {
  const token: AOMSessionToken = yield select((state) => state.login.tokenMap)

  if (token) {
    yield put(loginActions.loginWithToken(token))

    try {
      const loginResult: LoginResult = yield call(() => UserService.loginWithToken(token))
      yield put(loginActions.loginSuccess(loginResult))
      yield put(DashboardSlice.actions.loadContacts())
    } catch (err) {
      yield put(loginActions.loginFailure(err as any))
    }
  }

  yield put(StartUpSlice.actions.startUpSuccess())
}
