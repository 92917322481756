import {
  Chip,
  MenuItem,
  Popover,
  Select,
  TextField,
  Button
} from "@material-ui/core"
import { Add as AddIcon } from "@material-ui/icons"
import { Autocomplete, createFilterOptions } from "@material-ui/lab"
import React, { useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { FilterSelection } from "../../Dashboard/DashboardSlice"
import {
  FilterOptions,
  FilterOption
} from "../../Dashboard/Models/FilterOption"
import { AutoCompleteOptions } from "../../Dashboard/Services/AutoCompleteService"
import "./FilterBar.scss"

interface FilterBarProps {
  onAdd?: (filter: FilterSelection) => void
  onDelete?: (index: number) => any
  autoCompleteOptions?: AutoCompleteOptions
  activeFilters: FilterSelection[]
  filterOptions: FilterOptions
}

interface ChipContentProps {
  filter: FilterSelection
}

const autoCompleteFilterOptions = createFilterOptions({ matchFrom: 'start', limit: 1000 })

const ChipContent: React.FC<ChipContentProps> = ({ filter }) => (
  <div className="chip-content">
    <span className="chip-content__key">{filter.displayTitle}</span>
    <span className="chip-content__value">{filter.value}</span>
  </div>
)

export const FilterBar: React.FC<FilterBarProps> = props => {
  const {
    onAdd = () => {},
    onDelete = () => {},
    activeFilters = [],
    filterOptions = [],
    autoCompleteOptions = {}
  } = props

  const defaultSelection = filterOptions[0] || null

  const [showPopover, setShowPopover] = useState(false)
  const [currentSelection, setCurrentSelection] = useState(
    defaultSelection as FilterOption | null
  )

  const ref = useRef(null)
  const { register, handleSubmit, control, setValue, watch, reset } = useForm()
  watch("selectField")

  const handleClick = () => {
    setShowPopover(true)
  }

  const handleClose = () => {
    setShowPopover(false)
  }

  const handleDelete = (index: number) => onDelete(index)

  const findSelectedOption = (value: string) => {
    return filterOptions.find(option => option.value === value)
  }

  const handleSelectionChange = (event: any) => {
    const value = event[0].target.value
    const selection = findSelectedOption(value)
    setValue("valueField", "")
    setCurrentSelection(selection || null)

    return value
  }

  const onSubmit = (data: any) => {
    const { selectField, valueField } = data
    const selectedFilterOption = findSelectedOption(selectField)
    const newFilter = {
      key: selectField,
      value: valueField || "",
      displayTitle: selectedFilterOption?.title || "unknown"
    }

    reset()
    setCurrentSelection(defaultSelection)
    setShowPopover(false)
    onAdd(newFilter)
  }

  return (
    <div className="filterbar">
      <span className="filterbar__tags">
        {activeFilters.map((filter, i) => (
          <Chip
            key={i}
            className="filterbar__tag"
            label={<ChipContent filter={filter} />}
            onDelete={() => handleDelete(i)}
          />
        ))}
      </span>
      <span className="filterbar__button" onClick={handleClick} ref={ref}>
        Filter hinzufügen
      </span>
      <Popover
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
        open={showPopover}
        anchorEl={ref.current}
      >
        <form
          className="filterbar__popover"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h5 className="filterbar__popover-title">Filter hinzufügen</h5>
          <div className="filterbar__input-container">
            <Controller
              name="selectField"
              onChange={handleSelectionChange}
              as={
                <Select className="filterbar__input" variant="outlined">
                  {filterOptions.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              }
              defaultValue={filterOptions[0]?.value}
              control={control}
            ></Controller>
            {currentSelection?.type === "autocomplete" ? (
              <Autocomplete
                className="filterbar__input"
                filterOptions={autoCompleteFilterOptions}
                options={
                  autoCompleteOptions[currentSelection?.value || ""]
                    ? Array.from(
                        autoCompleteOptions[currentSelection?.value || ""]
                      )
                    : []
                }
                getOptionLabel={option => option as string}
                renderInput={params => (
                  <TextField
                    variant="outlined"
                    name="valueField"
                    inputRef={register}
                    {...params}
                    label="Suche"
                  />
                )}
              />
            ) : null}
            {currentSelection?.type === "text" ? (
              <TextField
                variant="outlined"
                className="filterbar__input"
                name="valueField"
                inputRef={register}
                label="Suche"
              />
            ) : null}
            {currentSelection?.type === "boolean" ? (
              <Controller
                name="valueField"
                defaultValue="true"
                control={control}
                onChange={(ev: any) => ev[0].target.value}
                as={
                  <Select
                    className="filterbar__input"
                    defaultValue="true"
                    variant="outlined"
                  >
                    <MenuItem value="true">Ja</MenuItem>
                    <MenuItem value="false">Nein</MenuItem>
                  </Select>
                }
              />
            ) : null}
            {currentSelection?.type === "select" ? (
              <Controller
                name="valueField"
                defaultValue={
                  currentSelection.values && currentSelection.values[0]?.value
                }
                control={control}
                onChange={(ev: any) => ev[0].target.value}
                as={
                  <Select
                    variant="outlined"
                    className="filterbar__input"
                    defaultValue={
                      currentSelection.values &&
                      currentSelection.values[0]?.value
                    }
                  >
                    {currentSelection.values?.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            ) : null}
          </div>
          <Button
            className="filterbar__submit"
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Hinzufügen
          </Button>
        </form>
      </Popover>
    </div>
  )
}
