import React from 'react'
import { ThemeProvider, Backdrop, CircularProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import './App.scss'
import { LoginScreen } from './Login/LoginScreen'
import { DashboardScreen } from './Dashboard/Dashboard'
import { theme } from './Theme'

interface PrivateRouteProps {
  isAuthorized: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ isAuthorized, children }) => {
  return isAuthorized ? <>{children}</> : <Redirect to="/login" />
}

function App() {
  const isStartingUp: boolean = useSelector((state: any) => state.startup.isStartingUp)
  const user = useSelector((state: any) => state.login.user)

  return (
    <ThemeProvider theme={theme}>
      {isStartingUp ? (
        <Backdrop style={{ zIndex: 9999 }} open={true}>
          <CircularProgress color="primary" />
        </Backdrop>
      ) : (
        <Router>
          <div className="app">
            <Switch>
              <Route exact path="/login">
                <LoginScreen />
              </Route>
              <Route path="/dashboard">
                <PrivateRoute isAuthorized={!isStartingUp && !!user}>
                  <DashboardScreen />
                </PrivateRoute>
              </Route>
              <Route path="/" exact>
                <Redirect to="/dashboard" />
              </Route>
            </Switch>
          </div>
        </Router>
      )}
    </ThemeProvider>
  )
}

export default App
