import { FilterSelection } from '../DashboardSlice'
import { AreaManagement } from '@apiomat/sdk'


export class PagedResult<T> {
  private pageSize = 10
  private results: T[] = []
  private currentPage = 0
  private loading = false
  public completed = false

  constructor(private loadMethod: (query: string) => Promise<T[]>, private baseQuery: string) {

  }

  async next(): Promise<T[]> {
    if (this.loading || this.completed) {
      return this.results
    }

    const offset = this.pageSize * this.currentPage
    const query = `${this.baseQuery} offset ${offset} limit ${this.pageSize}`.trim()

    this.loading = true
    try {
      const newResults = await this.loadMethod(query)

      if (newResults.length > 0) {
        this.results = [...this.results, ...newResults]
        this.currentPage++
      } else {
        this.completed = true
      }
      this.loading = false
    } catch (err) {
      this.loading = false
      throw err
    }

    return this.results
  }
}

export const getPagedTasks = (filters?: FilterSelection[]): PagedResult<AreaManagement.Task> => {
  let baseQuery = ""

  if (!!filters) {
    baseQuery = filters.map(filter => `${filter.key} like "${filter.value}"`)
      .join(" AND ")
  }

  const loadMethod = (query: string) => AreaManagement.Task.getTasks(query)
  const pagedResult = new PagedResult<AreaManagement.Task>(loadMethod, baseQuery)

  return pagedResult
}

export const completeTask = async (task: AreaManagement.Task) => {
  const request = new AreaManagement.CompleteTaskRequest()
  request.taskId = task.ID!

  await request.save(false)
}
