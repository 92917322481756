import React, { ForwardedRef, SyntheticEvent } from 'react'
import "./OutlinedInput.scss"

interface OutlinedInputProps {
  type?: "text" | "password"
  name?: string
  label?: string
  placeholder?: string
  disabled?: boolean
  onChange?: (event: SyntheticEvent) => void
  onInput?: (event: SyntheticEvent) => void
  onBlur?: (event: SyntheticEvent) => void
}

export const OutlinedInput: React.FC<OutlinedInputProps> = React.forwardRef(({
  type = "text",
  label,
  ...props
}, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <div className="input-field">
      <label className="input-field__label">{label}</label>
      <input
        ref={ref}
        className="input-field__input"
        {...{ type, ...props }}
      />
    </div>
  )
})
