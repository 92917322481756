import { AppBar, makeStyles, Toolbar } from "@material-ui/core"
import React from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import { BurgerMenu } from "../Components/BurgerMenu/BurgerMenu"
import { CreateScreen } from "./Create/CreateScreen"
import { TasksScreen } from "./Tasks/TasksScreen"

const useStyles = makeStyles({
  logoContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center"
  },
  logo: {
    width: "5em",
    objectFit: "contain"
  },
  logoutButton: {},
  container: {
    marginTop: "6em"
  },
  paper: {
    height: "100px",
    backgroundColor: "hotpink"
  }
})

export const DashboardScreen: React.FC = () => {
  const classes = useStyles()
  let { path } = useRouteMatch()
  return (
    <>
      <AppBar>
        <Toolbar>
          <BurgerMenu />
          <div className={classes.logoContainer}>
            <img className={classes.logo} src="assets/logo.png" alt="logo" />
          </div>
        </Toolbar>
      </AppBar>
      <Switch>
        <Route path={`${path}/create`}>
          <CreateScreen />
        </Route>
        <Route path={`${path}/tasks`}>
          <TasksScreen />
        </Route>
        <Route exact path={path}>
          <Redirect to={`${path}/create`} />
        </Route>
      </Switch>
    </>
  )
}
