import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'normalize.css'

import { store } from './Store'
import { Provider } from 'react-redux'
import StartUpSlice from './StartUpSlice'
import axios from 'axios'

axios.interceptors.request.use((request) => {
  request.params = { ...request.params, 'x-fisa-context': 'backoffice' }

  return request
})

store.dispatch(StartUpSlice.actions.startUp())

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
